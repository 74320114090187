<!--
 * @Description: 
 * @Author: wangying
 * @Date: 2021-09-28 19:21:03
-->
<template>
  <div class="greenchain ptn_r content-view">
    <div class="w_auto greenSupply ptn_r" v-if="currentType && currentSubType">
      <div class="horizontal"></div>
      <div class="horizontal_2 flax">
        <p>{{ isEnglish ? 'Location': '当前位置' }}: {{ isEnglish && currentSubType.eName ? currentSubType.eName : currentSubType.typeName }}</p>
      </div>
      <div class=" about Impact">
        <div>{{ currentType.eName }}</div>
        <div v-if="!isEnglish">{{ currentType.typeName }}</div>
      </div>
      <div class="flex">
        <div class="grnin ">
          <div v-for="(sub, index) in currentType.subMap" :key="index" @click="updateType(sub)"
            :class="['flex grnin_1 ', sub === currentSubType ? 'green_bg' : 'green_color']">
            <p style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;" :title="isEnglish && sub.eName ? sub.eName : sub.typeName">{{ isEnglish && sub.eName ? sub.eName : sub.typeName }}</p>
          </div>
        </div>
        <div class="TheContainer">
          <div class="wh">
            <iframe style="width: 120%;height: 100%;min-height: 1024px;border: 0;margin-left: -100px;    scale: 0.8;" src="https://www.ipe.org.cn/IndustryRecord/Regulatory_Green.aspx" v-if="currentSubType && currentSubType.typeName === '环境合规性供应商白名单检索'"/>
            <iframe style="width: 100%;height: 100%;min-height: 1024px;border: 0;" src="https://greenlist.see.org.cn/user/UserData.aspx" v-else-if="currentSubType && currentSubType.typeName === '绿名单申报'"/>
            
            <iframe style="width: 100%;height: 100%;min-height: 1024px;border: 0;" src="https://greenlist.see.org.cn/Industry/GreenList.aspx?cmd=search&istenday=0&Page=1" v-else-if="currentSubType && currentSubType.typeName === '供应商绿名单'"/>
            <el-button @click="onTargetWhite" v-else-if="currentSubType && currentSubType.typeName === '白名单申报'" class="declare">{{isEnglish && currentSubType.eName ? currentSubType.eName : currentSubType.typeName}}</el-button>
            <Content v-else />
          </div>
        </div>
      </div>
    </div>
    <div class="w_auto greenSupply ptn_r" v-else-if="currentType">
      <div class="horizontal"></div>
      <div class="horizontal_2 flax">
        <p>{{ isEnglish ? 'Location': '当前位置' }}: {{ isEnglish && currentType.eName ? currentType.eName : currentType.typeName }}</p>
      </div>

      <div class=" about Impact">
        <div>{{ currentType.eName }}</div>
        <div v-if="!isEnglish">{{ currentType.typeName }}</div>
      </div>
      <div class="flex">
        <div class="grnin ">
        </div>
        <div class="TheContainer">
          <div class="wh">
            <Content />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import Content from './content/content'
export default {
  data() {
    return {
      flagNam: 1,
      tatleName: '行动简介'
    }
  },
  components: {
    Content
  },
  mounted() {
    // this.add(this.flagNam)
    this.flagNam = this.$route.meta.flagNam

    this.hub.$on('tatleNames', () => {
      // console.log(this.$route,12487);
      this.flagNam = this.$route.meta.flagNam
      // console.log(this.flagNam,this.$route.meta.flagNam);
      // this.add(this.flagNam)
    })
    this.updateData();
  },
  watch: {
    "$store.state.typeLists"(newValue, oldValue) {
      console.log('-----');
      this.updateData();
    }
  },
  computed: {
    ...mapGetters(['currentType', 'currentSubType', 'isEnglish', 'typeLists'])
  },
  methods: {
    ...mapActions(['changeSubType','changeType']),
      updateData() {
        console.log('this.params:', this.$route.params);
        let type = null;
        if (this.typeLists.length > 0 && this.$route.params.typeName) {
          type = this.typeLists.find(v=>v.eName && v.eName.replaceAll(' ', '') === this.$route.params.typeName);
          this.changeType(type);
        }
        console.log('---type:', type)
        if (this.typeLists.length > 0 && this.$route.params.subTypeName && type) {
          const sub = type.subMap.find(v=>v.eName && v.eName.replaceAll(' ', '') ===  this.$route.params.subTypeName);
          console.log('sub:', sub);
          this.changeSubType(sub);
        }
        console.log('type:', this.currentType);
        console.log('sub:', this.currentSubType);
        document.getElementsByClassName('content-view')[0].scrollIntoView(true);
    },
    updateType(sub) {
        this.changeSubType(sub);
        this.hub.$emit('currentSubType')
        if (this.$route.path.indexOf('/content/') === 0) {
          console.log(sub.eName.replace(' ', ''))
          this.$router.replace({ path: `/content/${this.currentType.eName.replaceAll(' ','')}/${sub.eName?sub.eName.replaceAll(' ', ''):''}` });
        }
        else {
          this.$router.replace({ path: `/${this.$route.path.split('/')[1]}/${sub.eName ? sub.eName.replaceAll(' ', ''):''}` });
        }
        document.getElementsByClassName('content-view')[0].scrollIntoView(true);
    },
    onTarget() {
      window.open("https://www.ipe.org.cn/IndustryRecord/Regulatory_Green.aspx", "_blank");
    },
    onTargetList() {
      window.open('http://greenlist.see.org.cn:8077/user/Login.aspx', "_blank");
    },
    onTargetWhite() {
      window.open('http://lxi.me/bg22z', "_blank");
    }
  }
}
</script>
<style lang="less" scoped>
.ptn_r {
  position: relative;
}

.flex {
  display: flex;
}
.declare {
    background: #55a11b;
    color: #fff;
}
.greenchain {
  min-height: 500px;
  overflow: hidden;
}

.horizontal {
  position: absolute;
  left: -200px;
  width: 540px;
  margin-top: 246px;
  border-top: 1px solid #666;
}

.horizontal_2 {
  position: absolute;
  top: 240px;
  left: 460px;
  width: 40000px;
  border-top: 5px solid #8fa926;

  p {
    position: absolute;
    top: -50px;
    border-left: 4px solid #8fa926;
    margin-left: 2px;
    padding-left: 20px;
    font-size: 18px;
  }
}

.about {
  position: absolute;
  top: 120px;
  left: 40px;

  // width: 240px;
  div:nth-of-type(1) {
    font-size: 35px;
    font-weight: 700;
    color: #8fa926;
  }

  div:nth-of-type(2) {
    margin-top: 10px;
    font-size: 16px;
    font-weight: 500;
  }
}

.grnin {
  padding-top: 320px;
  margin-left: 60px;
  width: 280px;

  .grnin_1 {
    justify-content: center;
    height: 60px;
    line-height: 60px;
    font-size: 20px;

    p {
      width: 280px;
      text-align: center;
    }
  }
}

.green_color {
  color: #8fa926;
}

.green_bg {
  background: #8fa926;
  color: #fff;
}

.TheContainer {
  padding: 247px 0 0 120px;
  margin-right: -50px;
  flex: 1;

  .wh {
    width: 100%;
    min-height: 900px;
  }
}
</style>